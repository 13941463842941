var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('validation-observer', {
    ref: "refFormObserver",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var invalid = _ref.invalid,
          pristine = _ref.pristine;
        return [_c('b-modal', {
          attrs: {
            "id": "modal-modify-change-passengers-info",
            "title": _vm.$t('reservation.editInfoPaxList'),
            "title-class": "text-airline font-medium-4 fw-600",
            "centered": "",
            "size": "lg",
            "no-close-on-backdrop": "",
            "no-enforce-focus": ""
          },
          on: {
            "show": _vm.showModalPassengersInfo
          },
          scopedSlots: _vm._u([{
            key: "modal-footer",
            fn: function fn(_ref2) {
              var close = _ref2.close;
              return [_c('b-button', {
                staticClass: "rounded-pill",
                attrs: {
                  "variant": "outline-secondary"
                },
                on: {
                  "click": function click($event) {
                    return close();
                  }
                }
              }, [_vm._v(" " + _vm._s(_vm.$t('reservation.back')) + " ")]), _c('b-button', {
                directives: [{
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: 'rgba(113, 102, 240, 0.15)',
                  expression: "'rgba(113, 102, 240, 0.15)'",
                  modifiers: {
                    "400": true
                  }
                }],
                staticClass: "btn-gradient",
                attrs: {
                  "pill": "",
                  "disabled": invalid || pristine
                },
                on: {
                  "click": _vm.submitAndCheckPriceHandle
                }
              }, [_vm._v(" " + _vm._s(_vm.$t('reservation.historyActions.CALCULATE_PRICE')) + " ")])];
            }
          }], null, true)
        }, [_vm.dataToEdit ? _c('div', [_c('b-alert', {
          staticClass: "p-75",
          attrs: {
            "variant": "warning",
            "show": ""
          }
        }, [_c('div', {
          staticClass: "text-danger"
        }, [_vm._v(" Lưu ý : "), _c('br'), _vm._v(" - Chỉ được đổi tên hành khách một lần không quá 2 ký tự (miễn phí). "), _c('br'), _vm._v(" - Mỗi lần thao tác chỉ sửa thông tin của 1 hành khách. ")])]), _vm._l(_vm.dataToEdit, function (pax, index) {
          return _c('b-row', {
            key: pax.paxId,
            staticClass: "mx-0 mb-75 py-50 border-secondary rounded-lg passenger_row",
            attrs: {
              "no-gutters": ""
            }
          }, [_c('b-col', {
            class: "d-flex-between ".concat(pax.isEdit ? 'mb-75' : ''),
            attrs: {
              "cols": "12"
            }
          }, [_c('span', {
            staticClass: "fw-700"
          }, [_vm._v(" " + _vm._s(pax.dataShow) + " ")]), _c('b-button', {
            staticClass: "rounded-lg mx-25",
            attrs: {
              "variant": pax.isEdit ? 'outline-danger' : 'primary',
              "size": "sm"
            },
            on: {
              "click": function click($event) {
                return _vm.handleEditPassenger(pax.paxId);
              }
            }
          }, [_vm._v(" " + _vm._s(pax.isEdit ? 'Huỷ' : 'Sửa') + " ")])], 1), pax.isEdit ? [_c('b-col', {
            attrs: {
              "cols": "12",
              "md": "4"
            }
          }, [_c('validation-provider', {
            attrs: {
              "name": "Last Name",
              "rules": "required"
            },
            scopedSlots: _vm._u([{
              key: "default",
              fn: function fn(validationContext) {
                return [_c('b-form-group', {
                  staticClass: "form_label",
                  scopedSlots: _vm._u([{
                    key: "label",
                    fn: function fn() {
                      return [_c('span', [_vm._v(" " + _vm._s(_vm.$t('reservation.Lastname')) + " "), _c('span', {
                        staticClass: "text-danger ml-25"
                      }, [_vm._v("(*)")])])];
                    },
                    proxy: true
                  }], null, true)
                }, [_c('b-form-input', {
                  staticClass: "font-medium-1 fw-600 text-uppercase",
                  attrs: {
                    "id": "Lastname".concat(pax.paxId),
                    "lazy-formatter": "",
                    "formatter": _vm.formatterNameOnly,
                    "state": _vm.getValidationState(validationContext) === false ? false : null
                  },
                  model: {
                    value: pax.lastName,
                    callback: function callback($$v) {
                      _vm.$set(pax, "lastName", $$v);
                    },
                    expression: "pax.lastName"
                  }
                }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
              }
            }], null, true)
          })], 1), _c('b-col', {
            attrs: {
              "cols": "12",
              "md": "4"
            }
          }, [_c('validation-provider', {
            attrs: {
              "name": "First Name",
              "rules": "required"
            },
            scopedSlots: _vm._u([{
              key: "default",
              fn: function fn(validationContext) {
                return [_c('b-form-group', {
                  staticClass: "form_label",
                  scopedSlots: _vm._u([{
                    key: "label",
                    fn: function fn() {
                      return [_c('span', [_vm._v(" " + _vm._s(_vm.$t('reservation.Firstname')) + " "), _c('span', {
                        staticClass: "text-danger ml-25"
                      }, [_vm._v("(*)")])])];
                    },
                    proxy: true
                  }], null, true)
                }, [_c('b-form-input', {
                  staticClass: "font-medium-1 fw-600 text-uppercase",
                  attrs: {
                    "id": "Firstname".concat(pax.paxId),
                    "lazy-formatter": "",
                    "formatter": _vm.formatterNameOnly,
                    "state": _vm.getValidationState(validationContext) === false ? false : null
                  },
                  model: {
                    value: pax.firstName,
                    callback: function callback($$v) {
                      _vm.$set(pax, "firstName", $$v);
                    },
                    expression: "pax.firstName"
                  }
                }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
              }
            }], null, true)
          })], 1), _c('b-col', {
            attrs: {
              "cols": "12",
              "md": "4"
            }
          }, [_c('validation-provider', {
            attrs: {
              "name": _vm.$t('reservation[\'DoB\']'),
              "rules": "required|ageGroup:OW,".concat(_vm.getBookingData.itineraries[0][0].departure.at, ",").concat(pax.paxType),
              "vid": "passenger-birthday"
            },
            scopedSlots: _vm._u([{
              key: "default",
              fn: function fn(validationContext) {
                return [_c('b-form-group', {
                  staticClass: "form_label",
                  scopedSlots: _vm._u([{
                    key: "label",
                    fn: function fn() {
                      return [_c('span', [_vm._v(" " + _vm._s(_vm.$t('reservation[\'DoB\']')) + " "), _c('span', {
                        staticClass: "text-danger ml-25"
                      }, [_vm._v("(*)")])])];
                    },
                    proxy: true
                  }], null, true)
                }, [_c('flat-pickr', {
                  staticClass: "d-none",
                  attrs: {
                    "id": "passenger-birthday".concat(pax.paxId)
                  },
                  model: {
                    value: pax.birthday,
                    callback: function callback($$v) {
                      _vm.$set(pax, "birthday", $$v);
                    },
                    expression: "pax.birthday"
                  }
                }), _c('div', {
                  staticClass: "form-control p-0",
                  class: validationContext.errors[0] ? 'is-invalid' : ''
                }, [_c('flat-pickr', {
                  staticClass: "form-control px-50 py-0",
                  attrs: {
                    "id": "passenger-birthday".concat(pax.paxId),
                    "name": _vm.$t('reservation[\'DoB\']'),
                    "config": Object.assign({}, _vm.configFlatPickr, {
                      altInputClass: 'form-control input px-50 py-0 border-transparent'
                    }),
                    "placeholder": _vm.$t('reservation[\'formatDoB\']')
                  },
                  model: {
                    value: pax.birthday,
                    callback: function callback($$v) {
                      _vm.$set(pax, "birthday", $$v);
                    },
                    expression: "pax.birthday"
                  }
                })], 1), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
              }
            }], null, true)
          })], 1)] : _vm._e()], 2);
        })], 2) : _c('div', {
          staticClass: "d-flex-center"
        }, [_c('b-spinner', {
          attrs: {
            "variant": "primary"
          }
        })], 1)])];
      }
    }])
  }), _c('b-modal', {
    attrs: {
      "id": "modal-modify-change-passengers-info-confirm-price",
      "title": _vm.$t('reservation.confirmInfoChange'),
      "title-class": "text-airline font-medium-3 fw-600",
      "centered": "",
      "no-close-on-backdrop": "",
      "no-enforce-focus": ""
    },
    on: {
      "show": _vm.showPriceHandle,
      "hide": _vm.hidePriceHandle
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn(_ref3) {
        var cancel = _ref3.cancel;
        return [_c('b-button', {
          staticClass: "center rounded-pill",
          attrs: {
            "size": "md",
            "variant": "outline-secondary"
          },
          on: {
            "click": function click($event) {
              return cancel();
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('reservation.back')) + " ")]), _c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(113, 102, 240, 0.15)',
            expression: "'rgba(113, 102, 240, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "btn-gradient border-right-0",
          attrs: {
            "pill": "",
            "disabled": !_vm.isConfirm || _vm.totalSeconds === 0 || !_vm.canChangeInfo
          },
          on: {
            "click": _vm.submitChangeInfoHandle
          }
        }, [_c('div', {
          staticClass: "d-flex-center px-25"
        }, [_vm._v(" " + _vm._s(_vm.$t('reservation.confirm')) + " ")])])];
      }
    }])
  }, [_vm.newPaxLists ? _c('div', [_c('b-table', {
    staticClass: "position-relative rounded",
    attrs: {
      "items": _vm.newPaxLists,
      "responsive": "",
      "fields": _vm.tableColumns,
      "show-empty": "",
      "empty-text": _vm.$t('noMatchingResult'),
      "outlined": "",
      "small": ""
    },
    scopedSlots: _vm._u([_vm._l(_vm.tableColumns, function (column) {
      return {
        key: "head(".concat(column, ")"),
        fn: function fn() {
          return [_c('span', {
            key: column.label,
            staticClass: "text-dark text-nowrap"
          }, [_vm._v(" " + _vm._s(_vm.$t("reservation.".concat(column))) + " ")])];
        },
        proxy: true
      };
    }), {
      key: "cell(no)",
      fn: function fn(data) {
        return [_c('span', {
          staticClass: "font-weight-bold"
        }, [_vm._v(_vm._s(data.index + 1))])];
      }
    }, {
      key: "cell(Firstname)",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_c('span', {
          staticClass: "font-weight-bold"
        }, [_vm._v(_vm._s(item.firstName))])];
      }
    }, {
      key: "cell(Lastname)",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_c('span', [_vm._v(_vm._s(item.lastName))])];
      }
    }, {
      key: "cell(birthday)",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [_c('span', [_vm._v(_vm._s(item.birthday ? _vm.formatDateMonth(item.birthday) : '-'))])];
      }
    }], null, true)
  }), _vm.priceChange ? _c('BCard', {
    staticClass: "border mb-50",
    attrs: {
      "header-bg-variant": "light-success",
      "header-class": "py-50",
      "body-class": "py-50 px-25"
    }
  }, [_c('div', {
    staticClass: "mt-1"
  }, [_vm.canChangeInfo ? [_vm.totalSeconds > 0 ? _c('b-alert', {
    staticClass: "m-50 py-50 text-center",
    attrs: {
      "show": "",
      "variant": _vm.canChangeInfo ? 'success' : 'danger'
    }
  }, [_c('span', {
    staticClass: "fw-700"
  }, [_vm._v(_vm._s(_vm.canChangeInfo ? _vm.$t("reservation[\'Can to change\']") : _vm.$t("reservation[\'Please contact booker for support\']")))])]) : _vm._e(), _vm.totalSeconds > 0 ? _c('b-alert', {
    staticClass: "m-50 py-50 text-center",
    attrs: {
      "show": "",
      "variant": "danger"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("reservation[\'Remaining time\']")) + ": "), _c('span', {
    staticClass: "text-body"
  }, [_vm._v(_vm._s(_vm.convertTotalSecondToMinutesAndSecond(_vm.totalSeconds)) + " ")])]) : _c('div', [_c('div', {
    staticClass: "text-danger text-center font-medium-2 fw-700"
  }, [_vm._v(" " + _vm._s(_vm.$t("reservation[\'Confirmation time has expired, please try again\']")) + " ")])])] : [_c('b-alert', {
    staticClass: "m-50 py-50 text-center",
    attrs: {
      "show": "",
      "variant": "danger"
    }
  }, [_c('span', {
    staticClass: "fw-700"
  }, [_vm._v(_vm._s(_vm.$t("reservation[\'Please contact booker for support\']")))])])]], 2), _c('BRow', {
    staticClass: "mx-0 mb-25"
  }, [_c('BCol', {
    staticClass: "text-right fw-600",
    attrs: {
      "cols": "12"
    }
  }, [_vm._v(" Phí đổi: "), _c('span', {
    staticClass: "fw-800 ml-75"
  }, [_vm._v(" " + _vm._s(_vm.formatCurrency(_vm.priceChange.fee)) + " (" + _vm._s(_vm.priceChange.currency || '') + ") ")])])], 1)], 1) : _vm._e(), _c('IAmConfirm', {
    attrs: {
      "is-confirm": _vm.isConfirm
    },
    on: {
      "update:isConfirm": function updateIsConfirm($event) {
        _vm.isConfirm = $event;
      },
      "update:is-confirm": function updateIsConfirm($event) {
        _vm.isConfirm = $event;
      }
    }
  })], 1) : _vm._e()])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }